import { Container, Row, Col } from "react-bootstrap";
import StarIcon from "./assets/icons/icon_ic_star.svg";
import CheckIcon from "./assets/icons/icon_ic_check.svg";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import ModalSlideShow from "./components/ModalSlideShow";
import ModalSlideShowOutscale from "./components/ModalSlideShowOutscale";

export default function Projets() {
  const [isHoverProjets, setisHoverProjets] = useState(false);
  const [isVisibleProjets, setisVisibleCompTech] = useState(false);

  useEffect(() => {
    if (!isVisibleProjets) {
      let timer1 = setTimeout(() => setisVisibleCompTech(true), 1000);

      return () => {
        clearTimeout(timer1);
      };
    }
  });

  return (
    <Container className="projets">
      <Row>
        <Col>
          <div
            className={
              "my-card card-projet appear " + (isVisibleProjets ? "" : "d-none")
            }
            onMouseEnter={() => setisHoverProjets(true)}
            onMouseLeave={() => setisHoverProjets(false)}
          >
            <div class="card-title">
              <img src={CheckIcon} alt="" />
              Projets
            </div>
            <div class="card-content">
              {/* Ondorse info*/}
              <Row className="row-projet">
                <Col sm="3" md="3" lg="2" className="pb-2">
                  <span class="medium ">
                    avril 2024 - juillet 2024 <br />
                    (3 mois)
                  </span>
                </Col>
                <Col sm="9" md="9" lg="10">
                  <p class="f16">
                    <img src={StarIcon} className="sm-icon" alt="" />
                    <span className={"bold "}>Ondorse</span>, Paris, France – Ondorse est une startup fondée par des experts en
                    conformité qui <span
                      className={
                        "medium " + (isHoverProjets ? "hue-color" : "")
                      }>simplifie la surveillance des exigences réglementaires</span> pour les institutions.
                    Elle intègre des services de vérification dans les workflows existants et fournit des
                    informations exploitables.
                  </p>
                  <span className={"bold "}>Développeur Full Stack</span>
                  <ul>

                    <li>
                      Développement d'une application web en TypeScript (framework ReactJS) pour le frontend et
                      en Python (framework FastAPI) pour le backend. Refactoring de code, maintenance de
                      l'application, ajout de nouvelles fonctionnalités. Écriture de tests unitaires pour
                      le backend. Mise en place et développement de tests E2E avec Playwright.
                    </li>
                    <li>
                      <span
                        className={
                          "medium " + (isHoverProjets ? "hue-color" : "")
                        }
                      >Python</span> (<span
                        className={
                          "medium " + (isHoverProjets ? "hue-color" : "")
                        }
                      >FastAPI</span>, Pydantic, Pytest, SQLAlchemy), <span
                        className={
                          "medium " + (isHoverProjets ? "hue-color" : "")
                        }
                      >TypeScript</span> (<span
                        className={
                          "medium " + (isHoverProjets ? "hue-color" : "")
                        }
                      >ReactJS</span>, Chakra UI, Formik, React Query), Docker, PlayWright,
                      CSS, Docker Compose, GitHub, PostgreSQL, Figma.
                    </li>
                  </ul>
                </Col>
              </Row>
              {/* Outscale info*/}
              <Row className="row-projet">
                <Col sm="3" md="3" lg="2" className="pb-2">
                  <span class="medium ">
                    janvier 2023 - mars 2024 <br />
                    (1 an, 3 mois)
                  </span>
                </Col>
                <Col sm="9" md="9" lg="10">
                  <p class="f16">
                    <img src={StarIcon} className="sm-icon" alt="" />
                    <span className={"bold "}>Outscale</span>, Paris, France –
                    Outscale (filiale de Dassault Systèmes) est une entreprise française spécialisée
                    dans le <span
                      className={
                        "medium " + (isHoverProjets ? "hue-color" : "")
                      }
                    >cloud computing</span> qui propose des services d'Infrastructure en tant que
                    Service (IaaS).
                  </p>
                  <span className={"bold "}>Développeur Full Stack</span>
                  <ul>
                    <li>
                      Le Marketplace d'Outscale est un
                      catalogue numérique qui permet aux clients de trouver, acheter et commencer immédiatement à utiliser
                      des logiciels et services fonctionnant sur les services cloud d'Outscale. Les clients peuvent
                      bénéficier du Marketplace d'Outscale en simplifiant le processus d'acquisition et de déploiement
                      des solutions logicielles. Ils peuvent parcourir rapidement le catalogue, sélectionner le logiciel
                      répondant à leurs exigences et le déployer.
                    </li>
                    <li>
                      Développement d'une application frontend en TypeScript+ReactJS, développement
                      backend en Python+Django. Écriture de tests unitaires pour le backend et le frontend.
                      Mise en œuvre CI/CD en utilisant GitLab CI/CD. Configuration et écriture de tests E2E
                      (Playwright). Écriture et exécution de tests de charge avec Locust.
                    </li>
                    <li>
                      <span
                        className={
                          "medium " + (isHoverProjets ? "hue-color" : "")
                        }
                      >Python</span> (<span
                        className={
                          "medium " + (isHoverProjets ? "hue-color" : "")
                        }
                      >Django</span>, Django Ninja, Django ORM), <span
                        className={
                          "medium " + (isHoverProjets ? "hue-color" : "")
                        }
                      >TypeScript (ReactJS)</span>, Docker,
                      Docker Compose, GitLab CI/CD, S3, PostgreSQL, Keycloak, Unittest, Antd components,
                      Styled components, CSS, MobX, Celery, Redis, Locust, Playwright.

                    </li>
                  </ul>
                  <ModalSlideShowOutscale isHoverProjets={isHoverProjets} />
                </Col>
              </Row>
              {/* BForCure info*/}
              <Row className="row-projet">
                <Col sm="3" md="3" lg="2" className="pb-2">
                  <span class="medium ">
                    mars 2022 - juin 2022 <br />
                    (4 mois)
                  </span>
                </Col>
                <Col sm="9" md="9" lg="10">
                  <p class="f16">
                    <img src={StarIcon} className="sm-icon" alt="" />
                    <span className={"bold "}>BForCure</span>, Paris, France –
                    BForCure est une startup française développant une plateforme
                    d'analyse PCR qui combine un système automatisé mobile et connecté
                    avec une gamme de kits pouvant effectuer des tests pour les maladies infectieuses.
                  </p>
                  <span className={"bold "}>Senior BackEnd développeur</span>
                  <ul>
                    <li>
                      Développement du backend pour une application
                      d'analyse des résultats de tests PCR. Cela incluait le développement
                      et la maintenance de l'API, les processus d'extraction, de transformation
                      et de chargement des données, l'optimisation de l'utilisation de la base
                      de données BigQuery en utilisant SQLAlchemy. <span
                        className={
                          "medium " + (isHoverProjets ? "hue-color" : "")
                        }
                      >Réduction des coûts </span>
                      opérationnels de BigQuery <span
                        className={
                          "medium " + (isHoverProjets ? "hue-color" : "")
                        }
                      >par 8</span>. Développement de tests unitaires.
                    </li>
                    <li>
                      <span
                        className={
                          "medium " + (isHoverProjets ? "hue-color" : "")
                        }
                      >
                        Python
                      </span>{" "}
                      (Framework{" "}
                      <span
                        className={
                          "medium " + (isHoverProjets ? "hue-color" : "")
                        }
                      >
                        FastAPI
                      </span>
                      ), GitLab, BigQuery, Google Cloud Platform, Docker,
                      SQLAlchemy
                    </li>
                  </ul>
                </Col>
              </Row>
              {/* Qualibat info*/}
              <Row className="row-projet">
                <Col sm="3" md="3" lg="2" className="pb-2">
                  <span class="medium ">
                    juin 2020 - décembre 2021 <br />
                    (1,5 ans)
                  </span>
                </Col>
                <Col sm="9" md="9" lg="10">
                  <p class="f16">
                    <img src={StarIcon} className="sm-icon" alt="" />
                    <span className={"bold "}>Qualibat</span>, Paris, France –
                    Qualibat est une organisation française pour la qualification
                    et la certification des entreprises de construction.
                  </p>
                  <span className={"bold "}>
                    Team lead
                  </span>
                  <ul>
                    <li className="pt-2">
                      En tant que Team Lead chez Qualibat, j'ai dirigé une équipe de cinq personnes
                      composée de deux développeurs full-stack, un développeur front-end, un
                      designer UX/UI, et un consultant BI. Mes responsabilités incluaient
                      le développement backend, UX design et la conception des maquettes
                      dans Figma. Nous avons travaillé sur une nouvelle application à partir de
                      zéro pour gérer les certifications des entreprises de construction, servant
                      60 000 clients. Notre stack technique comprenait Flask et Python pour
                      le backend, ReactJS et TypeScript pour le frontend, GitLab CI/CD, Redis
                      et Celery pour la gestion des tâches lourdes (telles que la génération de documents
                      et l'envoi d'e-mails), et AWS S3 pour le stockage de fichiers. Le principal
                      défi du projet était sa logique métier complexe. Je me suis concentré
                      sur la conception de composants réutilisables et l'organisation du travail
                      pour livrer un produit flexible répondant aux exigences métier.
                    </li>

                    <li>
                      <span
                        className={
                          "medium " + (isHoverProjets ? "hue-color" : "")
                        }
                      >
                        Python
                      </span>{" "}
                      (Framework{" "}
                      <span
                        className={
                          "medium " + (isHoverProjets ? "hue-color" : "")
                        }
                      >
                        Flask
                      </span>
                      ), TypeScript (Framework{" "}
                      <span
                        className={
                          "medium " + (isHoverProjets ? "hue-color" : "")
                        }
                      >
                        ReactJS
                      </span>
                      ), GitLab, Docker, GitLab CI/CD, PostgreSQL, Amazon S3
                    </li>
                  </ul>
                  <ModalSlideShow isHoverProjets={isHoverProjets} />
                </Col>
              </Row>
              {/* Astudium info */}
              <Row className="row-projet">
                <Col sm="3" md="3" lg="2" className="pb-2">
                  <span class="medium ">
                    à partir de mai 2018<br />
                    (5 ans)
                  </span>
                </Col>
                <Col sm="9" md="9" lg="10">
                  <p class="f16">
                    <img src={StarIcon} className="sm-icon" alt="" />
                    <span className={"bold "}>Astudium</span>, Paris, France -
                    Astudium.com est une plateforme en ligne qui relie les clients avec des espaces uniques.
                  </p>
                  <span className={"bold "}>Fondateur, Développeur Full Stack</span>
                  <ul>
                    <li className="pt-2">
                      J'ai occupé plusieurs rôles dans ce projet : Fondateur, Product Owner,
                      et seul développeur Fullstack. J'ai collaboré avec un designer pour
                      conceptualiser la mise en page du site en utilisant Figma. Je travaille
                      sur tous les aspects du développement de l'application. J'ai employé une
                      stack technique moderne : FastAPI pour le backend, ReactJS avec
                      TypeScript pour le frontend, Celery avec Redis pour les fonctions
                      prenant du temps comme le redimensionnement d'images et l'envoi
                      d'e-mails, AWS S3 pour le stockage d'images, Docker et Docker Compose
                      pour la conteneurisation et le déploiement. Un accomplissement notable
                      pour Astudium a été son apparition sur la première page des résultats
                      de recherche Google pour "studio photo Paris".
                    </li>
                    <li>Python (FastAPI), TypeScript (ReactJS), PostgreSQL, Docker, Docker-compose, Redis, Celery, PHP, MySQL, Git, online payments, MangoPay, Figma</li>
                  </ul>
                  <Button
                    className="btn-primary mt-3"
                    href="http://astudium.com/"
                    target="_blank"
                  >
                    <span className={isHoverProjets ? "hue-color" : ""}>
                      Visiter le site
                    </span>
                  </Button>
                </Col>
              </Row>

              {/* Oakridge info */}
              <Row className="row-projet">
                <Col sm="3" md="3" lg="2" className="pb-2">
                  <span class="medium ">
                    août 2014 - mars 2018
                    <br />
                    (3 ans 8 mois)
                  </span>
                </Col>
                <Col sm="9" md="9" lg="10">
                  <p class="f16">
                    <img src={StarIcon} className="sm-icon" alt="" />
                    <span className={"bold "}>Oakridge</span>, Orléans, France -
                    société de conseil
                  </p>
                  <span className={"bold "}>Consultant</span>
                  <p class="f16 pt-2">
                    Missions chez <span className="bold">Thales</span>, Cergy,
                    France (2 ans 2 mois)
                  </p>
                  <ul>
                    <li className="pt-2">
                      Développeur, Chef de projet (équipe de 4 ingénieurs)
                    </li>
                    <li>
                      Réalisation et développement logiciel simulateur de
                      centrales nucléaire CP1 et PP4, formation de junior
                      ingénieurs, systèmes de contrôle-command, analyse de
                      modification, tests unitaires, tests d'integration
                    </li>
                    <li>
                      Python, Fortran, OOoBasic, ClearCase, Aglae5, simulateurs
                      de centrale nucléaire pleine échelle
                    </li>
                  </ul>
                  <p class="f16 pt-3">
                    Missions chez l'IRSN (Institut de radioprotection et de
                    surete nucleaire), Fontenay-aux-Roses (7 mois)
                  </p>
                  <ul>
                    <li className="pt-2">Développeur</li>
                    <li>
                      Création de base de données pour le code de calcul CLASS,
                      validation de code de calcul VESTA, création de
                      méthodologie pour l’établissement de la liste des
                      équipements sismiques
                    </li>
                    <li>
                      <span
                        className={
                          "medium " + (isHoverProjets ? "hue-color" : "")
                        }
                      >
                        Calculs cloud
                      </span>
                      , développement d'algorithmes, visualisation de données,
                      analyse de données
                    </li>
                    <li>
                      <span
                        className={
                          "medium " + (isHoverProjets ? "hue-color" : "")
                        }
                      >
                        Python
                      </span>
                      , R, AWS (Amazon Web Services), MCNP, R, parsing, VESTA,
                      Linux
                    </li>
                  </ul>
                  {/* EDF info */}
                </Col>
              </Row>
              <Row className="row-projet">
                <Col sm="3" md="3" lg="2" className="pb-2">
                  <span class="medium ">
                    avril 2013 - octobre 2013 <br />
                    (6 mois)
                  </span>
                </Col>
                <Col sm="9" md="9" lg="10">
                  <p class="f16">
                    <img src={StarIcon} className="sm-icon" alt="" />
                    <span className={"bold "}>EDF R&D</span>, Clamart, France -
                    EDF est le premier producteur et le premier fournisseur
                    d'électricité en France et en Europe
                  </p>
                  <span className={"bold "}>Stagiaire</span>
                  <ul>
                    <li className="pt-2">
                      Projet de fin d'études "Propagations d’incertitudes liées
                      aux données nucléaires de base".
                    </li>
                    <li>
                      Développement et réalisation de Monte Carlo Méthode pour
                      calcul d'incertitudes.
                    </li>

                    <li>
                      Python (NumPy), calculs parallèles, analyse de Data,
                      parsing, Linux, APOLLO2, TRIPOLI
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Container >
  );
}

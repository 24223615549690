import { Container, Row, Col } from "react-bootstrap";
import StarIcon from "./assets/icons/icon_ic_star.svg";
import CheckIcon from "./assets/icons/icon_ic_check.svg";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import ModalSlideShowClimbApp from "./components/ModalSlideShowClimbApp";

export default function AutresProjets() {
  const [isHoverAutresProjets, setisHoverAutresProjets] = useState(false);
  const [isVisibleProjets, setisVisibleCompTech] = useState(false);

  useEffect(() => {
    if (!isVisibleProjets) {
      let timer1 = setTimeout(() => setisVisibleCompTech(true), 1200);

      return () => {
        clearTimeout(timer1);
      };
    }
  });

  return (
    <Container className="projets">
      <Row>
        <Col>
          <div
            className={
              "my-card card-projet appear " + (isVisibleProjets ? "" : "d-none")
            }
            onMouseEnter={() => setisHoverAutresProjets(true)}
            onMouseLeave={() => setisHoverAutresProjets(false)}
          >
            <div class="card-title">
              <img src={CheckIcon} alt="" />
              Autres projets
            </div>
            <div class="card-content">
              {/* BForCure info*/}
              <Row className="row-projet">
                <Col sm="3" md="3" lg="2" className="pb-2">
                  <span class="medium ">
                    2022
                  </span>
                </Col>
                <Col sm="9" md="9" lg="10">
                  <p class="f16">
                    <img src={StarIcon} className="sm-icon" alt="" />
                    <span className={"bold "}>ToDo</span>, Paris, France –
                    Une petite application <span
                      className={
                        "medium " + (isHoverAutresProjets ? "hue-color" : "")
                      }
                    >open-source</span> pour tester le framework FastAPI et créer un modèle pour les autres applications.
                  </p>
                  <span className={"bold "}>FullStack développeur</span>
                  <ul>
                    <li>
                      Développement BackEnd avec le framework python FastAPI.
                      Création de routes: création d'utilisateurs, connexion,
                      mot de passe oublié et autres. Développement FrontEnd
                      avec le framework JavaScript ReactJS et package React
                      Bootstrap. Containerization de composants d'application
                      avec Docker et Docker-compose pour pouvoir cloner le projet et deployer un
                      environment de développement avec une seule commande.
                      Création de maquettes avec auto-layout dans Figma.
                    </li>
                    <li>
                      <span
                        className={
                          "medium " + (isHoverAutresProjets ? "hue-color" : "")
                        }
                      >
                        Python
                      </span>{" "}
                      (Framework{" "}
                      <span
                        className={
                          "medium " + (isHoverAutresProjets ? "hue-color" : "")
                        }
                      >
                        FastAPI
                      </span>
                      ), JavaScript (Framework <span
                        className={
                          "medium " + (isHoverAutresProjets ? "hue-color" : "")
                        }
                      >ReactJS</span>), <span
                        className={
                          "medium " + (isHoverAutresProjets ? "hue-color" : "")
                        }
                      >Docker</span>, Docker-compose, Nginx, Redis, SqlLite, GitLab, SQLAlchemy, MailHog, Figma.
                    </li>
                  </ul>
                  <Button
                    className="btn-primary mt-3"
                    href="https://gitlab.com/FedorGN/todo-fastapi-reactjs"
                    target="_blank"
                  >
                    <span className={isHoverAutresProjets ? "hue-color" : ""}>
                      Visiter le repository GitLab
                    </span>
                  </Button>
                </Col>
              </Row>
              {/* ClimbingApp info*/}
              <Row className="row-projet">
                <Col sm="3" md="3" lg="2" className="pb-2">
                  <span class="medium ">
                    2022
                  </span>
                </Col>
                <Col sm="9" md="9" lg="10">
                  <p class="f16">
                    <img src={StarIcon} className="sm-icon" alt="" />
                    <span className={"bold "}>ClimbingApp Roc14</span>, Paris, France –
                    ClimbingApp est une application web pour le club d'escalade Roc14. Un reseau social
                    pour suivre votre progrès et le progrès de vos amis.
                  </p>
                  <span className={"bold "}>
                    FullStack développeur
                  </span>
                  <ul>
                    <li>
                      Conception et développement d'application web ClimbingApp.

                      Conception de maquettes <span
                        className={
                          "medium " + (isHoverAutresProjets ? "hue-color" : "")
                        }
                      >Figma
                      </span>
                      , développement BackEnd et FrontEnd d'application.
                    </li>
                    <li>
                      <span
                        className={
                          "medium " + (isHoverAutresProjets ? "hue-color" : "")
                        }
                      >
                        Python
                      </span>{" "}
                      (Framework{" "}
                      <span
                        className={
                          "medium " + (isHoverAutresProjets ? "hue-color" : "")
                        }
                      >
                        FastAPI
                      </span>
                      ), JavaScript (Framework{" "}
                      <span
                        className={
                          "medium " + (isHoverAutresProjets ? "hue-color" : "")
                        }
                      >
                        ReactJS
                      </span>
                      ), GitLab, Docker, Docker-compose, Nginx, PostgreSQL, PgAdmin, Redis, Bootstrap, MailHog
                    </li>
                  </ul>
                  <ModalSlideShowClimbApp isHoverAutresProjets={isHoverAutresProjets} />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
